/* Section ----------------------------------------*/
.content {
	img {
		max-width: 100%;
		width: auto;
	}
}
.page-section {
	box-sizing:border-box;
	&::after {
		content: '';
		display: table;
		clear: both;
	}
	.page-wrapper {
		.entry-content {
			margin-right: 55px;
			> p,
			> h1,> h2,> h3,> h4,> h5,> h6,
			> ol,> ul,> dl,
			> .wp-block-column,
			> .wp-block-columns:not(.front-board):not(.alignfull),
			> .wp-block-group:not(.alignfull),
			> .wp-block-table:not(.alignfull),
			> .wp-block-button:not(.alignfull),
			> .wp-block-image:not(.alignfull),
			> .wp-block-cover:not(.alignfull),
			> .wp-block-quote:not(.alignfull),
			> .wp-block-file:not(.alignfull),
			> .wp-block-calendar,
			> .wp-block-gallery:not(.alignfull):not(.is-style-slider):not(.is-style-slider-simple),
			> .wp-block-media-text,
			> .wp-block-articlelist:not(.front-board),
			> .wp-block-archives.wp-block-archives-list,//archives
			> .wp-block-categories.wp-block-categories-list,//categories
			> .wp-block-latest-posts.wp-block-latest-posts__list,//new posts
			> .wp-block-separator,//hr
			> .wp-block-spacer,
			> .wp-block-embed,
			> .wp-block-mjblocks-dl {
				margin: 0 auto;
				// padding: 0 60/800*100vw;
				max-width: 800px;
				box-sizing: border-box;
				&::after {
					content: '';
					display: table;
					clear: both;
				}
			}
			&.wide {
				> p,
				> h1,> h2,> h3,> h4,> h5,> h6,
				> ol,> ul,> dl,
				> .wp-block-column,
				> .wp-block-columns:not(.front-board):not(.alignfull),
				> .wp-block-group:not(.alignfull),
				> .wp-block-table:not(.alignfull),
				> .wp-block-button:not(.alignfull),
				> .wp-block-image:not(.alignfull),
				> .wp-block-cover:not(.alignfull),
				> .wp-block-quote:not(.alignfull),
				> .wp-block-file:not(.alignfull),
				> .wp-block-calendar,
				> .wp-block-gallery:not(.alignfull):not(.is-style-slider):not(.is-style-slider-simple),
				> .wp-block-media-text,
				> .wp-block-articlelist:not(.front-board),
				> .wp-block-archives.wp-block-archives-list,//archives
				> .wp-block-categories.wp-block-categories-list,//categories
				> .wp-block-latest-posts.wp-block-latest-posts__list,//new posts
				> .wp-block-separator,//hr
				> .wp-block-spacer,
				> .wp-block-embed,
				> .wp-block-mjblocks-dl {
					max-width: 1155px;
					padding: 0 60/1155*100vw;
				}
			}

			p {
				margin-bottom: 1.8em;
				&:last-of-type {
					margin-bottom: 0;
				}
				&.is-style-lead-text {
					font-size: percentage(18px/$fontsize);
				}
			}
			ul:not([class]) {
				padding-left: 1em;
				list-style: none;
				li {
					margin-bottom: 10px;
					display: flex;
					align-items: baseline;
					&:last-of-type {
						margin-bottom: 0;
					}
					&::before {
						content: '';
						margin-left: -1em;
						display: block;
						width: 1em;
						height: 7px;
						background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-donut%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%207%207%22%3E%20%3Cpath%20d%3D%22M3.5%2C7A3.5%2C3.5%2C0%2C1%2C1%2C7%2C3.5%2C3.5%2C3.5%2C0%2C0%2C1%2C3.5%2C7Zm0-5A1.5%2C1.5%2C0%2C1%2C0%2C5%2C3.5%2C1.5%2C1.5%2C0%2C0%2C0%2C3.5%2C2Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
						background-position: 50% 50%;
					}
				}
			}
			ol:not([class]) {
				padding-left: 1em;
				list-style: none;
				counter-reset: number;
				li {
					margin-bottom: 10px;
					display: flex;
					align-items: baseline;
					&:last-of-type {
						margin-bottom: 0;
					}
					&::before {
						margin-left: -1em;
						font-weight: 700;
						counter-increment: number;
						content: counter(number)'.';
					}
				}
			}
			h1{}
			h2,
			h3,
			h4 {
				font-weight: 700;
			}
			h2 {
				font-size: percentage(26px/$fontsize);
				display: flex;
				align-items: center;
				&::before {
					content: '';
					margin-right: 0.5em;
					display: inline-block;
					width: 4px;
					height: 30px;
					background: #000;
				}
				&.has-text-align-center {
					font-size: percentage(30px/$fontsize);
					display: block;
					&::before {
						content: none;
					}
					&::after {
						content: '';
						margin: 0.5em auto 0;
						display: block;
						width: 60px;
						height: 4px;
						background: #000;
					}
				}
				&#area898-calendar,
				&#area898-news,
				&#area898-access {
					font-size: percentage(20px/$fontsize);
					justify-content: center;
				}
				&#area898-calendar {
					&::before {
						width: 30px;
						height: 25px;
						background: transparent url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-calendar%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2031.62%2026.24%22%3E%20%3Cpath%20d%3D%22M28.26%2C26.24H3.37A3.37%2C3.37%2C0%2C0%2C1%2C0%2C22.87V1.54H31.62V22.87A3.37%2C3.37%2C0%2C0%2C1%2C28.26%2C26.24ZM2%2C3.54V22.87a1.37%2C1.37%2C0%2C0%2C0%2C1.37%2C1.37H28.26a1.36%2C1.36%2C0%2C0%2C0%2C1.36-1.37V3.54Z%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%2219.26%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%2219.26%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.78%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.78%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.78%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2222.22%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2222.22%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2222.22%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%226.65%22%20width%3D%222%22%20height%3D%225.08%22%2F%3E%20%3Crect%20x%3D%2222.98%22%20width%3D%222%22%20height%3D%225.08%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
					}
				}
				&#area898-news {
					&::before {
						width: 28px;
						height: 20px;
						background: transparent url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-news%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2029.93%2022.21%22%3E%20%3Cpath%20d%3D%22M26.55%2C22.21H3.37A3.38%2C3.38%2C0%2C0%2C1%2C0%2C18.84V0H26.82V3h3.11v15.8A3.38%2C3.38%2C0%2C0%2C1%2C26.55%2C22.21ZM2%2C2V18.84a1.37%2C1.37%2C0%2C0%2C0%2C1.37%2C1.37H26.55a1.38%2C1.38%2C0%2C0%2C0%2C1.38-1.38V5H26.82V18.47h-2V2Z%22%2F%3E%20%3Cpath%20d%3D%22M15.09%2C10.69H3.89V3.76h11.2Zm-9.2-2h7.2V5.76H5.89Z%22%2F%3E%20%3Crect%20x%3D%223.57%22%20y%3D%2212.69%22%20width%3D%2219.78%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%223.57%22%20y%3D%2216.68%22%20width%3D%2219.78%22%20height%3D%222%22%2F%3E%3Crect%20x%3D%2216.54%22%20y%3D%224.65%22%20width%3D%226.82%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.54%22%20y%3D%228.79%22%20width%3D%226.82%22%20height%3D%222%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
					}
				}
				&#area898-access {
					justify-content: initial;
					&::before {
						width: 14px;
						height: 18px;
						background: transparent url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-access%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014.14%2018.39%22%3E%20%3Cpath%20d%3D%22M6.33%2C0a7.08%2C7.08%2C0%2C0%2C0-6%2C4.74C-1.46%2C10.2%2C4.08%2C15.48%2C6.14%2C18a1.22%2C1.22%2C0%2C0%2C0%2C1.72.09L8%2C18c2-2.41%2C6.18-6.5%2C6.18-10.9A7.08%2C7.08%2C0%2C0%2C0%2C7.06%2C0%2C6%2C6%2C0%2C0%2C0%2C6.33%2C0Zm.85%2C9.58A2.93%2C2.93%2C0%2C1%2C1%2C10.1%2C6.69h0A2.93%2C2.93%2C0%2C0%2C1%2C7.18%2C9.62Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
					}
				}

			}
			h3 {
				font-size: percentage(24px/$fontsize);
				&.has-text-align-center {
					font-size: percentage(26px/$fontsize);
				}
			}
			h4,
			h5,
			h6 {
				font-size: percentage(20px/$fontsize);
			}



			.wp-block-columns {
				display: flex;
				&.is-style-center {
					align-items: center;
				}
				&.is-style-end-bottom {
					& > .wp-block-column {
						display: flex;
						flex-direction: column;
						p:last-of-type {
							margin-top: auto;
						}
					}
				}
			}
			.wp-block-group {}
			.wp-block-table {
				table {
					width: 100%;
					border-spacing: 0;
					box-sizing: border-box;
					border-collapse: collapse;
					th,
					td {
						box-sizing: border-box;
						padding: 15px 20px;
						border: 1px solid #ccc;
						text-align: left;
					}
					th,
					td:first-child {
						background: #F5F6F7;
					}
				}
			}
			.wp-block-button {
				.wp-block-button__link {
					padding: 10px 24px;
					border-radius: 0;
					text-decoration: none;
					font-size: percentage(14px/$fontsize);
					&:not([class*='has-']) {
						border: 1px solid #000;
						color: #000;
						background-color: transparent;
					}
				}
				&.is-style-button-wide {
					.wp-block-button__link {
						padding: 10px (70/1367)*100vw;
					}
				}
				&.is-style-button-white {
					.wp-block-button__link {
						border: 1px solid #fff;
						color: #fff;
					}
				}
			}
			.wp-block-image {}
			.wp-block-cover {
				min-height: initial;
				&.is-style-cover-guidance {
					background: #F5F6F7;
					& > .wp-block-cover__inner-container {
						padding: (80/1366)*100vw;
						max-width: 1140px;
						.wp-block-columns {
							margin-bottom: 0;
							.wp-block-column {
								&:nth-child(2n) {
									margin-left: (80/1366)*100vw;
								}
								.has-background-dim {
									padding: (80/1366)*100vw (125/1366)*100%;
									box-sizing: border-box;
									&::before {
										opacity: 0.3;
									}
								}
							}
						}
					}
					.wp-block-button {
						.wp-block-button__link {
							border-color: #fff;
							color: #fff;
							font-size: percentage(20px/$fontsize);
							font-weight: 700;
						}
					}
				}
				&.contact-nav {
					padding: 120px 0;
					color: #fff;
					text-align: center;
					&::before {
						opacity: 0.3;
					}
					.wp-block-button {
						.wp-block-button__link {
							border-color: #fff;
							color: #fff;
							font-size: percentage(20px/$fontsize);
							font-weight: 700;
						}
					}
				}
			}
			.wp-block-quote {
				padding: 60px 35px;
				border-left: 0;
				position: relative;
				background: #F5F6F7;
				&::before {
					content: '';
					position: absolute;
					left: 14px;
					top: 20px;
					display: block;
					width: 30px;
					height: 25px;
					background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-doubke-quotation%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2031.29%2026.66%22%3E%20%3Cpath%20d%3D%22M7.08%2C26.66h0A7.08%2C7.08%2C0%2C0%2C1%2C0%2C19.58C.33%2C5.34%2C8%2C.36%2C8.37.15L8.61%2C0h2.78a1.92%2C1.92%2C0%2C0%2C1%2C1.14.39%2C1.8%2C1.8%2C0%2C0%2C1%2C.28%2C2.54c-1.32%2C1.65-4.26%2C5.71-4.68%2C9.65A7.08%2C7.08%2C0%2C0%2C1%2C7.08%2C26.66ZM9.22%2C2C8%2C2.89%2C2.27%2C7.83%2C2%2C19.6a5.07%2C5.07%2C0%2C0%2C0%2C5.07%2C5.06h0a5.08%2C5.08%2C0%2C0%2C0%2C0-10.16h-1v-1c0-4.48%2C3-9%2C4.92-11.5ZM11.4%2C2Z%22%2F%3E%20%3Cpath%20d%3D%22M24.21%2C26.66h0a7.08%2C7.08%2C0%2C0%2C1-7.07-7.08C17.46%2C5.34%2C25.17.36%2C25.5.15L25.74%2C0h2.8a1.79%2C1.79%2C0%2C0%2C1%2C1.12.4%2C1.81%2C1.81%2C0%2C0%2C1%2C.28%2C2.53c-1.32%2C1.65-4.27%2C5.71-4.69%2C9.65a7.1%2C7.1%2C0%2C0%2C1%2C6%2C7%2C7.08%2C7.08%2C0%2C0%2C1-7.08%2C7.07ZM26.35%2C2c-1.19.89-7%2C5.83-7.22%2C17.6a5.07%2C5.07%2C0%2C0%2C0%2C5.07%2C5.06h0a5.08%2C5.08%2C0%2C1%2C0%2C0-10.16h-1v-1c0-4.47%2C3-9%2C4.93-11.5Zm2.18%2C0Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
				}
			}
			.wp-block-file {
				a {
					&::after {
						content: '';
						margin-left: 6px;
						display: inline-block;
						width: 13px;
						height: 13px;
						background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-pdf%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2013.23%2013.63%22%3E%20%3Cpolygon%20points%3D%2211.88%2013.63%200%2013.63%200%203.02%202.83%200%2011.88%200%2011.88%203.56%2010.88%203.56%2010.88%201%203.27%201%201%203.42%201%2012.63%2010.88%2012.63%2010.88%209.83%2011.88%209.83%2011.88%2013.63%22%2F%3E%20%3Cpolygon%20points%3D%223.55%203.67%200.5%203.67%200.5%202.67%202.55%202.67%202.55%200.5%203.55%200.5%203.55%203.67%22%2F%3E%20%3Cpath%20d%3D%22M3.44%2C7.2V9H2.59V4.4H4.32a1.4%2C1.4%2C0%2C0%2C1%2C0%2C2.8Zm.85-.79a.62.62%2C0%2C0%2C0%2C0-1.23H3.44V6.41Z%22%2F%3E%20%3Cpath%20d%3D%22M6.15%2C9V4.4H7.59a2.31%2C2.31%2C0%2C0%2C1%2C0%2C4.6ZM7%2C8.21h.31a1.74%2C1.74%2C0%2C0%2C0%2C1-.3%2C1.72%2C1.72%2C0%2C0%2C0%2C0-2.43h0a1.67%2C1.67%2C0%2C0%2C0-1-.3H7Z%22%2F%3E%20%3Cpath%20d%3D%22M10.31%2C4.39h2.92v.79H11.17V6.24h1.66V7H11.17V9h-.86Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
						background-size: cover;
					}
				}
			}
			.wp-block-calendar {}
			.wp-block-gallery {
				ul {
					list-style: none;
				}
				&.is-style-fit {
					ul li {
						margin: 0;
					}
				}
			}
			.wp-block-articlelist {
				flex-wrap: wrap;
				article {
					flex-basis: calc((100% - 96px)*0.25);//@media (min-width: 782px)
					&:nth-child(4n-3){margin-left: 0;}
					a {
						text-decoration: none;
					}
					.post-thumbnail {
						width: 100%;
						position: relative;
						overflow: hidden;
						&::after {
							content: '';
							display: block;
							padding-top: (2/3)*100%;
						}
						img {
							position: absolute;
							top: 50%;
							left: 50%;
							width: auto;
							height: 100%;
							max-width: initial;
							max-width: auto;
							transform: translate(-50%, -50%);
						}
					}
					.post-meta {
					}
					.post-date {
						font-size: percentage(12px/$fontsize);
					}
					.post-category {
						padding: 0 5px;
						display: inline-block;
						font-size: percentage(12px/$fontsize);
						font-weight: 700;
						background: #e2e2e2;
					}
					.post-title {
						font-size: percentage(14px/$fontsize);
						font-weight: 700;
					}
					.post-content {
						font-size: percentage(12px/$fontsize);
					}
				}

				&.front-board {
					@keyframes animation-marquee {
						0% {
							transform: translate(0%);
						}
						100% {
							transform: translate(-100%);
						}
					}
					padding: 6px;
					border-top: 1px solid #000;
					border-bottom: 1px solid #000;
					height: 44px;
					overflow: hidden;
					position: relative;
					.front-board-list {
						white-space: nowrap;
						position: absolute;
						animation-name: animation-marquee;
						animation-duration: 50s;
						animation-timing-function: linear;
						animation-delay: 0s;
						animation-iteration-count: infinite;
						animation-direction: normal;
						article {
							display: inline-block;
							white-space: nowrap;
							border-right: 1px solid #000;
							a {
								padding: 11px;
								padding-right: 30vw;
								padding-left: 2em;
								position: relative;
								display: flex;
								align-items: center;
								& > div {
									margin-left: 1em;
									font-size: percentage(14px/$fontsize);
									&.post-title {
										font-weight: 400;
									}
								}
								&::after {
									content: '';
									position: absolute;
									right: 2em;
									display: block;
									width: 10px;
									height: 5px;
									background: url('data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22icon-arrowtop%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2030.47%2015.94%22%3E%20%3Cpath%20d%3D%22M30.18%2C15.94a.51.51%2C0%2C0%2C1-.36-.15L15.45%2C1.41%2C1.06%2C15.79a.48.48%2C0%2C0%2C1-.7%2C0%2C.48.48%2C0%2C0%2C1%2C0-.7L15.45%2C0%2C30.53%2C15.09a.5.5%2C0%2C0%2C1-.35.85Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
									transform: rotate(90deg);
								}
							}
							&:first-child {
								a {
									padding-left: 100vw;
								}
							}

						}
					}
					.wp-block-button {
						position: absolute;
						top: 6px;
						right: 0;
						background: #fff;
					}
				}
			}
			.wp-block-archives.wp-block-archives-list,//archives
			.wp-block-categories.wp-block-categories-list,//categories
			.wp-block-latest-posts.wp-block-latest-posts__list {//new posts
				list-style: none;
			}

			.is-style-anchor-list {
				.wp-block-column {
					a {
						padding: 0.3em 1em;
						border-bottom: 2px solid #000;
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						font-size: percentage(14px/$fontsize);
						font-weight: 700;
						text-decoration: none;
						box-sizing: border-box;
						&::after {
							content: '';
							display: block;
							width: 10px;
							height: 5px;
							background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-arrowbottom%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.26%207.04%22%3E%20%3Cpolygon%20points%3D%225.63%207.04%200%201.41%201.41%200%205.63%204.22%209.85%200%2011.26%201.41%205.63%207.04%22%2F%3E%20%3C%2Fsvg%3E') 0 0 no-repeat;
						}
					}
				}
			}
			.definition-row {
				dt {
					float: none;
					clear: both;
					width: auto;
				}
				dd {
					padding: 0;
				}
				&.definition-faq {
					dt {
						font-size: percentage(20px/$fontsize);
						&::before {
							content: 'Q.';
						}
					}
					dd {
						&::before {
							content: 'A.';
						}
					}
				}
			}

			.fix-range {
				& > .wp-block-group__inner-container {
					display: flex;
					justify-content: space-between;
					.fix-nav {
						flex-basis: 473/1155*100vw;
						width: 473/1155*100vw;
						max-width: 473px;
						img {
							width: 100%;
							max-width: 100%;
						}
						.fix-element {
							position: relative;
							top: 0;
							z-index: 0;
							width: 473/1155*100vw;
							transition: top 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
							&.is-fixed {
								position: fixed;
								top: 70px;
								left: 60/1155*100vw;
							}
							.nav-image {
								img {
									margin: 0;
									height: auto;
								}
								& > .wp-block-group__inner-container {
									position: relative;
									.nav-image-list {
										li {
											margin: 0;
											width: 100%;
											height: 100%;
											position: absolute;
											top: 0;
											left: 0;
											z-index: 1;
											opacity: 0;
											transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
											&.current {
												opacity: 1;
											}
										}
									}
								}
							}
							.nav-side {
								list-style: none;
								display: flex;
								justify-content: center;
								flex-wrap: wrap;
								li {
									position: relative;
									font-size: 14px;
									cursor: pointer;
									&::after {
										content: '|';
									}
									&:first-child::before {
										content: '|';
									}
									a {
										display: inline-block;
										padding: 3px;
										&.current {
											color: #FF895D;
										}
									}
								}
							}
						}
					}
					.fix-content {
						overflow: hidden;
						flex-basis: 600/1155*100%;
						width: 600/1155*100%;
						flex-basis:50%;
						width: 50%;
						& > .wp-block-group__inner-container > div {
							border-bottom: 1px solid #000;
							height: 600px;
							padding-top: 100px;
							padding: 50px 0;
							&:first-of-type {
								padding-top: 0;
							}
						}
					}
				}
			}

			.is-style-slider,
			.is-style-slider-simple {
				.swiper-wrapper {
					flex-wrap: nowrap;
					.blocks-gallery-item {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						margin: 0;
						&.swiper-slide-thumb-active {
							&::before,
							&::after {
								content: '';
								position: absolute;
								display: block;
								box-sizing: border-box;
							}
							&::before {
								width: 34px;
								height: 18px;
								background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-eye%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2037.09%2020.21%22%3E%20%3Cpath%20d%3D%22M18.54%2C20.21c-9.86%2C0-17.66-9.07-18-9.46L0%2C10.1l.55-.64C.88%2C9.07%2C8.68%2C0%2C18.54%2C0s17.68%2C9.07%2C18%2C9.46l.55.64-.55.65C36.22%2C11.14%2C28.42%2C20.21%2C18.54%2C20.21ZM2.67%2C10.1c1.94%2C2%2C8.39%2C8.11%2C15.87%2C8.11s13.94-6.08%2C15.88-8.11C32.48%2C8.08%2C26%2C2%2C18.54%2C2S4.61%2C8.08%2C2.67%2C10.1Z%22%2F%3E%20%3Cpath%20d%3D%22M18.62%2C19.79a9.9%2C9.9%2C0%2C1%2C1%2C9.9-9.9A9.91%2C9.91%2C0%2C0%2C1%2C18.62%2C19.79ZM18.62%2C2a7.9%2C7.9%2C0%2C1%2C0%2C7.9%2C7.89A7.9%2C7.9%2C0%2C0%2C0%2C18.62%2C2Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
								background-position: 50% 50%;
								z-index: 2;
							}
							&::after {
								border: 2px solid #000;
								width: 100%;
								height: 100%;
								background: #fff;
								opacity: 0.4;
								z-index: 1;
							}
						}
					}
				}
			}

			.alignfull {
				padding: 0;
				max-width: 100%;
				&.wp-block-columns-gallery {
					.wp-block-column {
						margin: 0;
					}
				}
			}
			.alignwide {
				max-width: 800px;
			}
		}//entry-content
	}

	a {
		color:#000;
		text-decoration:underline;
		// &:hover {
		// 	background: #EDEDED;
		// }
		&.blank {
			&::after {
				content: '';
				margin-left: 6px;
				display: inline-block;
				width: 13px;
				height: 13px;
				background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-link%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2013.74%2013.78%22%3E%20%3Cpolygon%20points%3D%2212.96%2011.57%202.17%2011.57%202.17%200.79%207.57%200.79%207.57%201.78%203.17%201.78%203.17%2010.57%2011.96%2010.57%2011.96%206.18%2012.96%206.18%2012.96%2011.57%22%2F%3E%20%3Cpolygon%20points%3D%2213.74%205.39%2012.74%205.39%2012.74%201%208.35%201%208.35%200%2013.74%200%2013.74%205.39%22%2F%3E%20%3Cpolygon%20points%3D%2210.28%2013.78%200%2013.78%200%203.5%201%203.5%201%2012.78%2010.28%2012.78%2010.28%2013.78%22%2F%3E%20%3Crect%20x%3D%227.34%22%20y%3D%222.45%22%20width%3D%226.92%22%20height%3D%221%22%20transform%3D%22translate(1.08%208.5)%20rotate(-45)%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
				background-size: cover;
			}
		}
	}
	.visual-container {
		display: flex;
		.thumbnail-image {
			flex-basis: (683/1367)*100vw;
			img {
				width: 100%;
			}
		}
		.feature-container {
			flex-basis: calc(100% - (683/1367)*100vw);
		}
		&.front-visual {
			margin-right: 55px;
			.front-title {
				flex-basis: 50vw;
			}
			.front-gallery {
				flex-basis: 50vw;
				width: 50vw;
				overflow: hidden;
				.is-style-slider-simple {
					.swiper-wrapper {
						display: flex;
						flex-wrap: nowrap;
						.swiper-slide {
							margin: 0;
						}
					}

				}

			}

		}
	}

	.feature-container {
		margin-right: 55px;
		padding: 0 30/1155*100vw;
		flex-basis: initial;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.feature-title {
			font-size: percentage(30px/$fontsize);
			font-weight: 700;
		}
		.feature-catch {
			font-size: percentage(18px/$fontsize);
		}
	}


	.wpcf7 {
		margin: 0 auto;
		max-width: 800px;
		box-sizing: border-box;
		br {
			display: none;
		}
		.wpcf7-validation-errors,
		.wpcf7-acceptance-missing,
		.wpcf7-mail-sent-ok,
		.wpcf7-mail-sent-ng,
		.wpcf7-spam-blocked {
			border: 0;
			background: #F5F6F7;
			padding: 2em 1em;
		}
		.wpcf7-response-output {
			margin: 0;
		}
		.wpcf7-not-valid-tip {
			font-size: percentage(12px/$fontsize);
		}
		.form-group {
			border-left: 1px solid #000;
			border-right: 1px solid #000;
			border-top: 1px solid #000;
			&:last-of-type {
				border-bottom: 1px solid #000;
			}
			display: flex;
			label {
				padding: 1em;
				border-right: 1px solid #000;
				font-weight: 700;
				background: #F5F6F7;
				flex-basis: 33%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				span.asterisk {
					padding: 0 3px;
					font-size: percentage(14px/$fontsize);
					font-weight: 400;
					background: #fff;
					&.required {
						color: #fff;
						background: #FF5522;
					}
				}
			}
			.wpcf7-form-control-wrap {
				padding: 1em;
				flex-basis: 67%;
			}
		}
	}

}

/* Element ----------------------------------------*/

dl.definition {
	dt {
		font-weight: 900;
	}
}
dl.definition-column {
	dt {
		float: left;
		clear: left;
		width: 9em;
	}
	dd {
		overflow: hidden;
	}
}
dl.definition-row {
	dt {
		display: block;
	}
	dd {
		margin-bottom: 1.8em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
dl.indentline {
	dt {
		font-size: percentage(20px/$fontsize);
		font-weight: 900;
		letter-spacing: (130/1000)*1em;
		&::before {
			content: '';
			margin-right: 1em;
			transform: translateY(-3px);
			display: inline-block;
			width: 31px;
			height: 5px;
			background: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-indentline%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2031%205%22%3E%20%3Cpath%20d%3D%22M4%2C5%2C0%2C1.24.89.41%2C4%2C3.33%2C7.58%2C0l3.49%2C3.26%2C3-2.85.89.84L11.07%2C4.92%2C7.58%2C1.66Z%22%2F%3E%20%3Cpath%20d%3D%22M20%2C5%2C16%2C1.24l.89-.83L20%2C3.33%2C23.58%2C0l3.49%2C3.26%2C3-2.85.89.84L27.07%2C4.92%2C23.58%2C1.66Z%22%2F%3E%20%3C%2Fsvg%3E') no-repeat;
		}
	}
	dd {
		margin-bottom: 1.8em;
		font-size: percentage(17px/$fontsize);
		letter-spacing: (130/1000)*1em;
		line-height: (32/17);
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


.breadcrumb {
	ol {
		margin: 0 auto;
		padding-top: (13/1367)*100vw;
		padding-bottom: (13/1367)*100vw;
		padding-left: (17/1367)*100vw;
		padding-right: 55px;
		max-width: 1311px;
		list-style: none;
		box-sizing: border-box;
		display: flex;
		align-items: top;
		li {
			&::after {
				content: '>';
				margin: 0 0.5em;
			}
			&:last-of-type::after {
				content: none;
				margin: 0;
			}
		}
	}
}
.pagination {
	text-align: center;
	display: flex;
	justify-content: space-between;
	.num {
		text-align: center;
		display: flex;
		justify-content: center;
	}
	span,
	a {
		margin: 0 5px;
		border: 1px solid #000;
		display: block;
		width: 44px;
		height: 44px;
		line-height: 44px;
		text-align: center;
		text-decoration: none;
		color: #000;
		box-sizing: border-box;
	}
	span.current {
		border: 1px solid #e2e2e2;
		color: #fff;
		background: #e2e2e2;
		cursor: default;
	}
	.prev,
	.next {
		padding-left: 20px;
		padding-right: 20px;
		width: auto;
		&.disabled {
			border: 1px solid transparent;
			color: transparent;
			cursor: default;
		}
	}
	.prev {
		margin-left: 0;
	}
	.next {
		margin-right: 0;
	}
}

.hide {
	display: none;
}
.aligncenter {
	text-align: center;
	margin: 0 auto;
}
.alignleft {}
.alignright {}
