@charset "UTF-8";
/*
Theme Name: Area 898
Author: the Area 898 team
Description: This is Area 898 Theme
Version: 1.0
*/
@import "assets/css/fullcalender";

$fontsize:16px;
$font_default: 'Noto Sans JP', 'Yu Gothic', '游ゴシック', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
/*------------------------
	Reset
------------------------ */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, figure, figcaption,  a, abbr, address, cite, code, del, dfn, em, font, img, video, ins, kbd, q, samp, small, strong, sub, sup, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, header, nav, article, aside, footer, hgroup, section, i
{margin:0; padding:0; border:0; outline:0; font-weight:inherit; font-style:inherit; font-size:100%; font-family:inherit; text-decoration:none;}
input, select, textarea, button {
	font-family: $font_default;
	appearance: none;
	&::-ms-expand {	display: none;}
}
input[type="text"], input[type='search'], input[type='url'], input[type='email'], input[type='tel'],
input[type='password'], input, button, select, textarea {
	padding: 0;
	border: none;
	border-radius: 0;
	outline: none;
	display:inline-block;
	box-shadow: 0 0 0 0 transparent;
	box-sizing:border-box;
	background: transparent;
	font-size: $fontsize;
}
input[type="text"], input[type='search'], input[type='url'], input[type='email'], input[type='tel'],
input[type='password'], select, textarea {
	display: block;
	width: 100%;
	&::placeholder {
		color: #aaa;
	}
}
/*------------------------
	Common
------------------------ */
html {	scroll-behavior: smooth;}
body {
	overflow-x: hidden;
	position: relative;
	color: #000000;
	font-size:$fontsize;
	font-family:$font_default;
	font-weight: 400;
	line-height: (26/16);
	letter-spacing: (20/1000)*1em;
	background: #ffffff;
	-moz-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}
:hover, :focus, :active {	outline:0}
a {	color: #000000;}
img {	vertical-align:top;}
strong {	font-weight: 700;}
header,
footer {
	ul {	list-style: none;}
}
header {
	position: relative;
	top: 0;
	left: 0;
	border-bottom: 1px solid #fff;
	width: 100%;
	box-sizing: border-box;
	transition: all 0.5s ease;
	z-index: 1000;
	.header-section {
		margin: 0 auto;
		padding-top: (13/1367)*100vw;
		padding-bottom: (13/1367)*100vw;
		padding-left: (17/1367)*100vw;
		padding-right: 55px;
		max-width: 1311px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: top;
	}
	.logo-brand {
		h1 {
			display: inline-block;
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: auto;
					height: 41px;
				}
			}
		}
	}
	.header-nav {
		ul {
			display: flex;
			justify-content: flex-start;
			li {
				a {
					padding: 1em (17/1367)*100vw;
					display: block;
					font-size: percentage(12px/$fontsize);
					font-weight: 900;
					transition: all 0.5s ease-out;
				}
			}
		}
	}
	&.unique_page {
		position: absolute;
		.logo-brand h1 a {
			.svg-name {
				fill: #fff;
			}
		}
		.header-nav a {
			color: #fff;
		}
	}
	&.is-fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		border-bottom: 1px solid #000;
		background: #fff;
		transition: all 0.5s ease, background 0.3s ease;
	}
}
#btn-nav {
	display: none;
}

footer {
	.footer-section {
		margin: 0 auto;
		display: flex;
		align-items: center;
		max-width: 1311px;
		box-sizing: border-box;
		.logo-name {
			display: flex;
			justify-content: center;
			align-items: center;
			width: (256/1367)*100vw;
			background: #F5F6F7;
			&::before {
				content: '';
				display: block;
				padding-top: 100%;
			}
			.svg {
				width: (111/1367)*100vw;
				height: auto;
			}
		}
		.logo-brand {
			padding: 0 (85/1367)*100vw;
			img {
				width: (231/1367)*100vw;
				height: auto;
			}
		}
		.company-overview {
			font-size: percentage(14px/$fontsize);
		}
	}
	.footer-nav {
		position: relative;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		.footer-section {
			padding-right: 55px;
			position: relative;
			max-height: 55px;
			justify-content: space-between;
		}
		ul {
			display: flex;
			justify-content: flex-start;
			li {
				a {
					padding: 18px (15/1367)*100vw;
					display: block;
					font-size: percentage(12px/$fontsize);
					font-weight: 900;
				}
			}
		}
		.footer-copyright {
			margin-right: (26/1367)*100vw;
			font-size: percentage(12px/$fontsize);
			font-weight: 900;
		}
	}
}
#scroll-top {
	position: absolute;
	position: fixed;
	bottom: 0;
	right: 0;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	z-index: 1010;
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 55px;
		height: 55px;
		.svg {
			width: 29.47px;
			height: 14.73px;
		}
	}
}

#primary-nav {
	// display: none;
	border-left: 1px solid #000;
	width: 55px;
	position: fixed;
	top: 0;
	right: 0;
	background: #fff;
	z-index: 1010;
	ul {
		list-style: none;
		li {
			display: flex;
			// align-items: center;
			justify-content: center;
			height: 50vh;
			a {
				// margin-top: 20px;
				// margin-bottom: 100px;
				padding: 20px 15px;
				writing-mode: vertical-rl;
				font-size: percentage(14px/$fontsize);
				letter-spacing: 0.5em;
				.svg {
					&.svg-calendar {
						width: 21.82px;
						height: 18.59px;
					}
					&.svg-access {
						width: 14.14px;
						height: 18.39px;
					}
					
				}
			}
			&:first-child {
				border-bottom: 1px solid #000;
				a {
					// margin-top: 100px;
					// margin-bottom: 0;
				}
			}
		}
	}

}

#global-wrapper {
	position: relative;
}

/*---------------------------------------------------------------
	Containers
-------------------------------------------------------------- */
@import "assets/css/classes";

$type: common wide pc tab tabmin sp;
$type: common;

@media(min-width: 1270px){
	$type: wide;
	@import "assets/css/media-query";
}
@media(max-width: 1270px){
	$type: pc;
	@import "assets/css/media-query";
}
@media(max-width: 1024px){
	$type: tab;
	@import "assets/css/media-query";
}
@media(max-width: 959px){
	$type: tabmin;
	@import "assets/css/media-query";
}
@media(max-width: 740px){
	$type: sp;
	@import "assets/css/media-query";
}

@import "assets/css/modernizr";