/* Mediaquery */
@if $type == sp or $type == tab {
	html {	-webkit-text-size-adjust: 100%;}
}

@if $type == pc {

}

@if $type == tab {

}

@if $type == tabmin {

}

@if $type == sp {
	header {
		padding: 0;
		display: block;
		.logo-brand {
				width: 100%;
				position: absolute;
				top: 14px;
				z-index: 1000;
			h1 {
				display: block;
				a {
					margin: 0 auto;
					height: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					.svg {
						display: block;
					}
					.svg-logo {
						margin-right: 4px;
						width: 39px;
						height: 32px;
					}
					.svg-name {
						margin-left: 4px;
						width: 70px;
						height: 18px;
					}
				}
			}
		}
		.header-nav {
			ul {
				display: block;
			}
		}
		&.is-fixed {
			padding: 0;
		}
	}
	.open {
		header {
			.logo-brand h1 a .svg-name {
				fill: #000;
			}
		}
	}
	#btn-nav {
		z-index: 1000;
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 60px;
		height: 60px;
		cursor: pointer;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 33%;
			height: 2px;
			display: block;
			background: #000;
			transform: translateX(-50%);
			transition: transform 0.3s ease-in-out, top 0.3s ease;
		}
		span {
			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 50%;
				width: 33%;
				height: 2px;
				display: block;
				background: #000;
				transform: translateX(-50%);
				transition: transform 0.3s ease-in-out, top 0.3s ease;
			}
			&::before {
				top: calc(50% - 5px);
			}
			&::after {
				top: calc(50% + 5px);
			}
		}
	}
	.open #btn-nav {
		&::before {
			top: 50%;
			left: 50%;
			width: 0;
		}
		span {
			&::before {
				top: 60%;
				left: calc(50% - 5px);
				transform: rotate(45deg) translateX(-50%);
			}
			&::after {
				top: 37%;
				left: calc(50% - 5px);
				transform: rotate(-45deg) translateX(-50%);
			}
		}
	}
	#global-nav {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 990;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 0;
		transform: translateX(-100%);
		transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
		nav {
			z-index: 999;
		}
		a {
			padding: 10px 0;
			display: block;
			font-size: percentage(24px/$fontsize);
		}
		ul {
			li {
				transform: translateX(-200px);
				transition:  transform 0.6s ease;
				position: relative;
				text-align: center;
				&:nth-child(2) {
					transition-delay: 0.15s;
				}
				&:nth-child(3) {
					transition-delay: 0.3s;
				}
				&:nth-child(4) {
					transition-delay: 0.45s;
				}
				&:nth-child(5) {
					transition-delay: 0.6s;
				}
				&:nth-child(6) {
					transition-delay: 0.75s;
				}
				&:nth-child(7) {
					transition-delay: 0.9s;
				}
			}
		}
	}
	.open #global-nav {
		transform: translateX(0);
		background: #fff;
		li {
			transform: translateX(0);
			a {
				color: #000;
			}
		}
	}
	footer {
		.page-wrapper {
			.footer-nav {
				justify-content: space-between;
			}
		}
	}

	.feature-title {
		font-size: (40/375)*100vw;
		letter-spacing: (130/1000)*1em;
	}
	.feature-catch {
		font-size: (24/375)*100vw;
		letter-spacing: (230/1000)*1em;
		line-height: (40/24);
	}

	#scroll-top {
		display: none;
	}
}
@if $type == sp {
	br.sp {
		display: block;
	}
}@else if{
	br.sp {
		display: none;
	}
}@else{
	br.sp {
		display: none;
	}
}

