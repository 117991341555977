@charset "UTF-8";
/*
Theme Name: Area 898
Author: the Area 898 team
Description: This is Area 898 Theme
Version: 1.0
*/
/*------------------------
	FullCalendar
------------------------ */
/*!* FullCalendar v1.6.1 Stylesheet	* Docs & License: http://arshaw.com/fullcalendar/	* (c) 2013 Adam Shaw	*/
.fc {
  direction: ltr;
  text-align: left;
}

.fc table {
  border-collapse: collapse;
  border-spacing: 0;
}

.fc table td,
.fc table th {
  padding: 0;
  vertical-align: top;
}

html .fc,
.fc table {
  font-size: 1em;
}

/* Header*/
.fc-header td {
  white-space: nowrap;
}

.fc-header-left {
  width: 25%;
  text-align: left;
}

.fc-header-center {
  text-align: center;
}

.fc-header-right {
  width: 25%;
  text-align: right;
}

.fc-header-title {
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  font-weight: 700;
}

.fc-header-title h2 {
  margin-top: 0;
  white-space: nowrap;
}

.fc .fc-header-space {
  padding-left: 10px;
}

.fc-header .fc-button {
  margin-bottom: 1em;
  vertical-align: top;
}

.fc-header .fc-button {
  margin-right: -1px;
}

.fc-header .fc-corner-right,
.fc-header .ui-corner-right {
  margin-right: 0;
}

.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
  z-index: 2;
}

.fc-header .fc-state-down {
  z-index: 3;
}

.fc-header .fc-state-active,
.fc-header .ui-state-active {
  z-index: 4;
}

.fc-content {
  clear: both;
}

.fc-view {
  width: 100%;
  overflow: hidden;
}

.fc-widget-header,
.fc-widget-content {
  border: 1px solid #ddd;
}

.fc-state-highlight {
  background: #fcf8e3;
}

.fc-cell-overlay {
  background: #bce8f1;
  opacity: .3;
  filter: alpha(opacity=30);
}

.fc-button {
  position: relative;
  display: inline-block;
  padding: 0 .6em;
  overflow: hidden;
  height: 1.9em;
  line-height: 1.9em;
  white-space: nowrap;
  cursor: pointer;
}

.fc-state-default.fc-corner-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fc-state-default.fc-corner-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fc-button-today {
  border: 1px solid #000;
  font-size: 14px;
}

.fc-button-prev,
.fc-button-next {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
}

.fc-button-prev {
  position: relative;
}

.fc-button-prev::after {
  content: '';
  position: absolute;
  right: 0;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  display: inline-block;
  width: 2px;
  height: 4px;
  background: #000;
}

.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow {
  font-weight: bold;
}

.fc-button .fc-icon-wrap {
  position: relative;
  float: left;
  top: 50%;
}

.fc-button .ui-icon {
  position: relative;
  float: left;
  margin-top: -50%;
  *margin-top: 0;
  *top: -50%;
}

.fc-state-hover {
  text-decoration: none;
}

.fc-state-down,
.fc-state-active {
  text-decoration: none;
  color: #fff;
  background: #000;
}

.fc-state-disabled {
  cursor: default;
}

.fc-event {
  border: 1px solid #EEFC60;
  background-color: #EEFC60;
  font-size: .85em;
  cursor: default;
}

a.fc-event {
  text-decoration: none;
}

a.fc-event,
.fc-event-draggable {
  cursor: pointer;
}

.fc-rtl .fc-event {
  text-align: right;
}

.fc-event-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
}

.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  font-size: 300%;
  line-height: 50%;
}

.fc-event-hori {
  border-width: 1px 0;
  margin-bottom: 1px;
}

.fc-ltr .fc-event-hori.fc-event-start,
.fc-rtl .fc-event-hori.fc-event-end {
  border-left-width: 1px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-right-width: 1px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fc-event-hori .ui-resizable-e {
  top: 0           !important;
  right: -3px      !important;
  width: 7px       !important;
  height: 100%     !important;
  cursor: e-resize;
}

.fc-event-hori .ui-resizable-w {
  top: 0           !important;
  left: -3px       !important;
  width: 7px       !important;
  height: 100%     !important;
  cursor: w-resize;
}

.fc-event-hori .ui-resizable-handle {
  _padding-bottom: 14px;
}

table.fc-border-separate {
  border-collapse: separate;
}

.fc-border-separate th,
.fc-border-separate td {
  border-width: 1px 0 0 1px;
}

.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
  border-right-width: 1px;
}

.fc-border-separate tr.fc-last th,
.fc-border-separate tr.fc-last td {
  border-bottom-width: 1px;
}

.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-first th {
  border-top-width: 0;
}

/* Month View, Basic Week View, Basic Day View
------------------------------------------------------------------------*/
.fc-grid th {
  text-align: center;
}

.fc .fc-week-number {
  width: 22px;
  text-align: center;
}

.fc .fc-week-number div {
  padding: 0 2px;
}

.fc-grid .fc-day-number {
  padding: 0 2px;
  text-align: center;
}

.fc-grid .fc-other-month .fc-day-number {
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
  /* opacity with small font can sometimes look too faded
				might want to set the 'color' property instead
				making day-numbers bold also fixes the problem */
}

.fc-grid .fc-day-content {
  clear: both;
  padding: 2px 2px 1px;
  /* distance between events and day edges */
}

/* event styles */
.fc-grid .fc-event-time {
  font-weight: bold;
}

/* right-to-left */
.fc-rtl .fc-grid .fc-day-number {
  float: left;
}

.fc-rtl .fc-grid .fc-event-time {
  float: right;
}

/* Agenda Week View, Agenda Day View
------------------------------------------------------------------------*/
.fc-agenda table {
  border-collapse: separate;
}

.fc-agenda-days th {
  text-align: center;
}

.fc-agenda .fc-agenda-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: right;
  white-space: nowrap;
  font-weight: normal;
}

.fc-agenda .fc-week-number {
  font-weight: bold;
}

.fc-agenda .fc-day-content {
  padding: 2px 2px 1px;
}

/* make axis border take precedence */
.fc-agenda-days .fc-agenda-axis {
  border-right-width: 1px;
}

.fc-agenda-days .fc-col0 {
  border-left-width: 0;
}

/* all-day area */
.fc-agenda-allday th {
  border-width: 0 1px;
}

.fc-agenda-allday .fc-day-content {
  min-height: 34px;
  /* TODO: doesnt work well in quirksmode */
  _height: 34px;
}

/* divider (between all-day and slots) */
.fc-agenda-divider-inner {
  height: 2px;
  overflow: hidden;
}

.fc-widget-header .fc-agenda-divider-inner {
  background: #eee;
}

/* slot rows */
.fc-agenda-slots th {
  border-width: 1px 1px 0;
}

.fc-agenda-slots td {
  border-width: 1px 0 0;
  background: none;
}

.fc-agenda-slots td div {
  height: 20px;
}

.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
  border-top-width: 0;
}

.fc-agenda-slots tr.fc-minor th,
.fc-agenda-slots tr.fc-minor td {
  border-top-style: dotted;
}

.fc-agenda-slots tr.fc-minor th.ui-widget-header {
  *border-top-style: solid;
  /* doesn't work with background in IE6/7 */
}

/* Vertical Events
------------------------------------------------------------------------*/
.fc-event-vert {
  border-width: 0 1px;
}

.fc-event-vert.fc-event-start {
  border-top-width: 1px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.fc-event-vert.fc-event-end {
  border-bottom-width: 1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fc-event-vert .fc-event-time {
  white-space: nowrap;
  font-size: 10px;
}

.fc-event-vert .fc-event-inner {
  position: relative;
  z-index: 2;
}

.fc-event-vert .fc-event-bg {
  /* makes the event lighter w/ a semi-transparent overlay  */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .25;
  filter: alpha(opacity=25);
}

.fc .ui-draggable-dragging .fc-event-bg,
.fc-select-helper .fc-event-bg {
  display: none\9;
  /* for IE6/7/8. nested opacity filters while dragging don't work */
}

/* resizable */
.fc-event-vert .ui-resizable-s {
  bottom: 0        !important;
  /* importants override pre jquery ui 1.7 styles */
  width: 100%      !important;
  height: 8px      !important;
  overflow: hidden !important;
  line-height: 8px !important;
  font-size: 11px  !important;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.fc-agenda .ui-resizable-resizing {
  /* TODO: better selector */
  _overflow: hidden;
}

/*------------------------
	Reset
------------------------ */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, figure, figcaption, a, abbr, address, cite, code, del, dfn, em, font, img, video, ins, kbd, q, samp, small, strong, sub, sup, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, header, nav, article, aside, footer, hgroup, section, i {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  text-decoration: none;
}

input, select, textarea, button {
  font-family: "Noto Sans JP", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", sans-serif;
  appearance: none;
}

input::-ms-expand, select::-ms-expand, textarea::-ms-expand, button::-ms-expand {
  display: none;
}

input[type="text"], input[type='search'], input[type='url'], input[type='email'], input[type='tel'],
input[type='password'], input, button, select, textarea {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  display: inline-block;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  background: transparent;
  font-size: 16px;
}

input[type="text"], input[type='search'], input[type='url'], input[type='email'], input[type='tel'],
input[type='password'], select, textarea {
  display: block;
  width: 100%;
}

input[type="text"]::placeholder, input[type='search']::placeholder, input[type='url']::placeholder, input[type='email']::placeholder, input[type='tel']::placeholder,
input[type='password']::placeholder, select::placeholder, textarea::placeholder {
  color: #aaa;
}

/*------------------------
	Common
------------------------ */
html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  position: relative;
  color: #000000;
  font-size: 16px;
  font-family: "Noto Sans JP", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", sans-serif;
  font-weight: 400;
  line-height: 1.625;
  letter-spacing: 0.02em;
  background: #ffffff;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

:hover, :focus, :active {
  outline: 0;
}

a {
  color: #000000;
}

img {
  vertical-align: top;
}

strong {
  font-weight: 700;
}

header ul,
footer ul {
  list-style: none;
}

header {
  position: relative;
  top: 0;
  left: 0;
  border-bottom: 1px solid #fff;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.5s ease;
  z-index: 1000;
}

header .header-section {
  margin: 0 auto;
  padding-top: 0.950987564vw;
  padding-bottom: 0.950987564vw;
  padding-left: 1.243599122vw;
  padding-right: 55px;
  max-width: 1311px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: top;
}

header .logo-brand h1 {
  display: inline-block;
}

header .logo-brand h1 a {
  display: flex;
  justify-content: center;
  align-items: center;
}

header .logo-brand h1 a img {
  width: auto;
  height: 41px;
}

header .header-nav ul {
  display: flex;
  justify-content: flex-start;
}

header .header-nav ul li a {
  padding: 1em 1.243599122vw;
  display: block;
  font-size: 75%;
  font-weight: 900;
  transition: all 0.5s ease-out;
}

header.unique_page {
  position: absolute;
}

header.unique_page .logo-brand h1 a .svg-name {
  fill: #fff;
}

header.unique_page .header-nav a {
  color: #fff;
}

header.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #000;
  background: #fff;
  transition: all 0.5s ease, background 0.3s ease;
}

#btn-nav {
  display: none;
}

footer .footer-section {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 1311px;
  box-sizing: border-box;
}

footer .footer-section .logo-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18.727139722vw;
  background: #F5F6F7;
}

footer .footer-section .logo-name::before {
  content: '';
  display: block;
  padding-top: 100%;
}

footer .footer-section .logo-name .svg {
  width: 8.119970739vw;
  height: auto;
}

footer .footer-section .logo-brand {
  padding: 0 6.217995611vw;
}

footer .footer-section .logo-brand img {
  width: 16.898317484vw;
  height: auto;
}

footer .footer-section .company-overview {
  font-size: 87.5%;
}

footer .footer-nav {
  position: relative;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

footer .footer-nav .footer-section {
  padding-right: 55px;
  position: relative;
  max-height: 55px;
  justify-content: space-between;
}

footer .footer-nav ul {
  display: flex;
  justify-content: flex-start;
}

footer .footer-nav ul li a {
  padding: 18px 1.097293343vw;
  display: block;
  font-size: 75%;
  font-weight: 900;
}

footer .footer-nav .footer-copyright {
  margin-right: 1.901975128vw;
  font-size: 75%;
  font-weight: 900;
}

#scroll-top {
  position: absolute;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  z-index: 1010;
}

#scroll-top a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
}

#scroll-top a .svg {
  width: 29.47px;
  height: 14.73px;
}

#primary-nav {
  border-left: 1px solid #000;
  width: 55px;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 1010;
}

#primary-nav ul {
  list-style: none;
}

#primary-nav ul li {
  display: flex;
  justify-content: center;
  height: 50vh;
}

#primary-nav ul li a {
  padding: 20px 15px;
  writing-mode: vertical-rl;
  font-size: 87.5%;
  letter-spacing: 0.5em;
}

#primary-nav ul li a .svg.svg-calendar {
  width: 21.82px;
  height: 18.59px;
}

#primary-nav ul li a .svg.svg-access {
  width: 14.14px;
  height: 18.39px;
}

#primary-nav ul li:first-child {
  border-bottom: 1px solid #000;
}

#global-wrapper {
  position: relative;
}

/*---------------------------------------------------------------
	Containers
-------------------------------------------------------------- */
/* Section ----------------------------------------*/
.content img {
  max-width: 100%;
  width: auto;
}

.page-section {
  box-sizing: border-box;
}

.page-section::after {
  content: '';
  display: table;
  clear: both;
}

.page-section .page-wrapper .entry-content {
  margin-right: 55px;
}

.page-section .page-wrapper .entry-content > p,
.page-section .page-wrapper .entry-content > h1, .page-section .page-wrapper .entry-content > h2, .page-section .page-wrapper .entry-content > h3, .page-section .page-wrapper .entry-content > h4, .page-section .page-wrapper .entry-content > h5, .page-section .page-wrapper .entry-content > h6,
.page-section .page-wrapper .entry-content > ol, .page-section .page-wrapper .entry-content > ul, .page-section .page-wrapper .entry-content > dl,
.page-section .page-wrapper .entry-content > .wp-block-column,
.page-section .page-wrapper .entry-content > .wp-block-columns:not(.front-board):not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-group:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-table:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-button:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-image:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-cover:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-quote:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-file:not(.alignfull),
.page-section .page-wrapper .entry-content > .wp-block-calendar,
.page-section .page-wrapper .entry-content > .wp-block-gallery:not(.alignfull):not(.is-style-slider):not(.is-style-slider-simple),
.page-section .page-wrapper .entry-content > .wp-block-media-text,
.page-section .page-wrapper .entry-content > .wp-block-articlelist:not(.front-board),
.page-section .page-wrapper .entry-content > .wp-block-archives.wp-block-archives-list,
.page-section .page-wrapper .entry-content > .wp-block-categories.wp-block-categories-list,
.page-section .page-wrapper .entry-content > .wp-block-latest-posts.wp-block-latest-posts__list,
.page-section .page-wrapper .entry-content > .wp-block-separator,
.page-section .page-wrapper .entry-content > .wp-block-spacer,
.page-section .page-wrapper .entry-content > .wp-block-embed,
.page-section .page-wrapper .entry-content > .wp-block-mjblocks-dl {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
}

.page-section .page-wrapper .entry-content > p::after,
.page-section .page-wrapper .entry-content > h1::after, .page-section .page-wrapper .entry-content > h2::after, .page-section .page-wrapper .entry-content > h3::after, .page-section .page-wrapper .entry-content > h4::after, .page-section .page-wrapper .entry-content > h5::after, .page-section .page-wrapper .entry-content > h6::after,
.page-section .page-wrapper .entry-content > ol::after, .page-section .page-wrapper .entry-content > ul::after, .page-section .page-wrapper .entry-content > dl::after,
.page-section .page-wrapper .entry-content > .wp-block-column::after,
.page-section .page-wrapper .entry-content > .wp-block-columns:not(.front-board):not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-group:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-table:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-button:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-image:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-cover:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-quote:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-file:not(.alignfull)::after,
.page-section .page-wrapper .entry-content > .wp-block-calendar::after,
.page-section .page-wrapper .entry-content > .wp-block-gallery:not(.alignfull):not(.is-style-slider):not(.is-style-slider-simple)::after,
.page-section .page-wrapper .entry-content > .wp-block-media-text::after,
.page-section .page-wrapper .entry-content > .wp-block-articlelist:not(.front-board)::after,
.page-section .page-wrapper .entry-content > .wp-block-archives.wp-block-archives-list::after,
.page-section .page-wrapper .entry-content > .wp-block-categories.wp-block-categories-list::after,
.page-section .page-wrapper .entry-content > .wp-block-latest-posts.wp-block-latest-posts__list::after,
.page-section .page-wrapper .entry-content > .wp-block-separator::after,
.page-section .page-wrapper .entry-content > .wp-block-spacer::after,
.page-section .page-wrapper .entry-content > .wp-block-embed::after,
.page-section .page-wrapper .entry-content > .wp-block-mjblocks-dl::after {
  content: '';
  display: table;
  clear: both;
}

.page-section .page-wrapper .entry-content.wide > p,
.page-section .page-wrapper .entry-content.wide > h1, .page-section .page-wrapper .entry-content.wide > h2, .page-section .page-wrapper .entry-content.wide > h3, .page-section .page-wrapper .entry-content.wide > h4, .page-section .page-wrapper .entry-content.wide > h5, .page-section .page-wrapper .entry-content.wide > h6,
.page-section .page-wrapper .entry-content.wide > ol, .page-section .page-wrapper .entry-content.wide > ul, .page-section .page-wrapper .entry-content.wide > dl,
.page-section .page-wrapper .entry-content.wide > .wp-block-column,
.page-section .page-wrapper .entry-content.wide > .wp-block-columns:not(.front-board):not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-group:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-table:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-button:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-image:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-cover:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-quote:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-file:not(.alignfull),
.page-section .page-wrapper .entry-content.wide > .wp-block-calendar,
.page-section .page-wrapper .entry-content.wide > .wp-block-gallery:not(.alignfull):not(.is-style-slider):not(.is-style-slider-simple),
.page-section .page-wrapper .entry-content.wide > .wp-block-media-text,
.page-section .page-wrapper .entry-content.wide > .wp-block-articlelist:not(.front-board),
.page-section .page-wrapper .entry-content.wide > .wp-block-archives.wp-block-archives-list,
.page-section .page-wrapper .entry-content.wide > .wp-block-categories.wp-block-categories-list,
.page-section .page-wrapper .entry-content.wide > .wp-block-latest-posts.wp-block-latest-posts__list,
.page-section .page-wrapper .entry-content.wide > .wp-block-separator,
.page-section .page-wrapper .entry-content.wide > .wp-block-spacer,
.page-section .page-wrapper .entry-content.wide > .wp-block-embed,
.page-section .page-wrapper .entry-content.wide > .wp-block-mjblocks-dl {
  max-width: 1155px;
  padding: 0 5.194805195vw;
}

.page-section .page-wrapper .entry-content p {
  margin-bottom: 1.8em;
}

.page-section .page-wrapper .entry-content p:last-of-type {
  margin-bottom: 0;
}

.page-section .page-wrapper .entry-content p.is-style-lead-text {
  font-size: 112.5%;
}

.page-section .page-wrapper .entry-content ul:not([class]) {
  padding-left: 1em;
  list-style: none;
}

.page-section .page-wrapper .entry-content ul:not([class]) li {
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}

.page-section .page-wrapper .entry-content ul:not([class]) li:last-of-type {
  margin-bottom: 0;
}

.page-section .page-wrapper .entry-content ul:not([class]) li::before {
  content: '';
  margin-left: -1em;
  display: block;
  width: 1em;
  height: 7px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-donut%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%207%207%22%3E%20%3Cpath%20d%3D%22M3.5%2C7A3.5%2C3.5%2C0%2C1%2C1%2C7%2C3.5%2C3.5%2C3.5%2C0%2C0%2C1%2C3.5%2C7Zm0-5A1.5%2C1.5%2C0%2C1%2C0%2C5%2C3.5%2C1.5%2C1.5%2C0%2C0%2C0%2C3.5%2C2Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
  background-position: 50% 50%;
}

.page-section .page-wrapper .entry-content ol:not([class]) {
  padding-left: 1em;
  list-style: none;
  counter-reset: number;
}

.page-section .page-wrapper .entry-content ol:not([class]) li {
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}

.page-section .page-wrapper .entry-content ol:not([class]) li:last-of-type {
  margin-bottom: 0;
}

.page-section .page-wrapper .entry-content ol:not([class]) li::before {
  margin-left: -1em;
  font-weight: 700;
  counter-increment: number;
  content: counter(number) ".";
}

.page-section .page-wrapper .entry-content h2,
.page-section .page-wrapper .entry-content h3,
.page-section .page-wrapper .entry-content h4 {
  font-weight: 700;
}

.page-section .page-wrapper .entry-content h2 {
  font-size: 162.5%;
  display: flex;
  align-items: center;
}

.page-section .page-wrapper .entry-content h2::before {
  content: '';
  margin-right: 0.5em;
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #000;
}

.page-section .page-wrapper .entry-content h2.has-text-align-center {
  font-size: 187.5%;
  display: block;
}

.page-section .page-wrapper .entry-content h2.has-text-align-center::before {
  content: none;
}

.page-section .page-wrapper .entry-content h2.has-text-align-center::after {
  content: '';
  margin: 0.5em auto 0;
  display: block;
  width: 60px;
  height: 4px;
  background: #000;
}

.page-section .page-wrapper .entry-content h2#area898-calendar, .page-section .page-wrapper .entry-content h2#area898-news, .page-section .page-wrapper .entry-content h2#area898-access {
  font-size: 125%;
  justify-content: center;
}

.page-section .page-wrapper .entry-content h2#area898-calendar::before {
  width: 30px;
  height: 25px;
  background: transparent url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-calendar%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2031.62%2026.24%22%3E%20%3Cpath%20d%3D%22M28.26%2C26.24H3.37A3.37%2C3.37%2C0%2C0%2C1%2C0%2C22.87V1.54H31.62V22.87A3.37%2C3.37%2C0%2C0%2C1%2C28.26%2C26.24ZM2%2C3.54V22.87a1.37%2C1.37%2C0%2C0%2C0%2C1.37%2C1.37H28.26a1.36%2C1.36%2C0%2C0%2C0%2C1.36-1.37V3.54Z%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%225.89%22%20y%3D%2219.26%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2211.34%22%20y%3D%2219.26%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.78%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.78%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.78%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2222.22%22%20y%3D%229.01%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2222.22%22%20y%3D%2212.43%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2222.22%22%20y%3D%2215.84%22%20width%3D%223.51%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%226.65%22%20width%3D%222%22%20height%3D%225.08%22%2F%3E%20%3Crect%20x%3D%2222.98%22%20width%3D%222%22%20height%3D%225.08%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
}

.page-section .page-wrapper .entry-content h2#area898-news::before {
  width: 28px;
  height: 20px;
  background: transparent url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-news%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2029.93%2022.21%22%3E%20%3Cpath%20d%3D%22M26.55%2C22.21H3.37A3.38%2C3.38%2C0%2C0%2C1%2C0%2C18.84V0H26.82V3h3.11v15.8A3.38%2C3.38%2C0%2C0%2C1%2C26.55%2C22.21ZM2%2C2V18.84a1.37%2C1.37%2C0%2C0%2C0%2C1.37%2C1.37H26.55a1.38%2C1.38%2C0%2C0%2C0%2C1.38-1.38V5H26.82V18.47h-2V2Z%22%2F%3E%20%3Cpath%20d%3D%22M15.09%2C10.69H3.89V3.76h11.2Zm-9.2-2h7.2V5.76H5.89Z%22%2F%3E%20%3Crect%20x%3D%223.57%22%20y%3D%2212.69%22%20width%3D%2219.78%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%223.57%22%20y%3D%2216.68%22%20width%3D%2219.78%22%20height%3D%222%22%2F%3E%3Crect%20x%3D%2216.54%22%20y%3D%224.65%22%20width%3D%226.82%22%20height%3D%222%22%2F%3E%20%3Crect%20x%3D%2216.54%22%20y%3D%228.79%22%20width%3D%226.82%22%20height%3D%222%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
}

.page-section .page-wrapper .entry-content h2#area898-access {
  justify-content: initial;
}

.page-section .page-wrapper .entry-content h2#area898-access::before {
  width: 14px;
  height: 18px;
  background: transparent url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-access%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014.14%2018.39%22%3E%20%3Cpath%20d%3D%22M6.33%2C0a7.08%2C7.08%2C0%2C0%2C0-6%2C4.74C-1.46%2C10.2%2C4.08%2C15.48%2C6.14%2C18a1.22%2C1.22%2C0%2C0%2C0%2C1.72.09L8%2C18c2-2.41%2C6.18-6.5%2C6.18-10.9A7.08%2C7.08%2C0%2C0%2C0%2C7.06%2C0%2C6%2C6%2C0%2C0%2C0%2C6.33%2C0Zm.85%2C9.58A2.93%2C2.93%2C0%2C1%2C1%2C10.1%2C6.69h0A2.93%2C2.93%2C0%2C0%2C1%2C7.18%2C9.62Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
}

.page-section .page-wrapper .entry-content h3 {
  font-size: 150%;
}

.page-section .page-wrapper .entry-content h3.has-text-align-center {
  font-size: 162.5%;
}

.page-section .page-wrapper .entry-content h4,
.page-section .page-wrapper .entry-content h5,
.page-section .page-wrapper .entry-content h6 {
  font-size: 125%;
}

.page-section .page-wrapper .entry-content .wp-block-columns {
  display: flex;
}

.page-section .page-wrapper .entry-content .wp-block-columns.is-style-center {
  align-items: center;
}

.page-section .page-wrapper .entry-content .wp-block-columns.is-style-end-bottom > .wp-block-column {
  display: flex;
  flex-direction: column;
}

.page-section .page-wrapper .entry-content .wp-block-columns.is-style-end-bottom > .wp-block-column p:last-of-type {
  margin-top: auto;
}

.page-section .page-wrapper .entry-content .wp-block-table table {
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;
  border-collapse: collapse;
}

.page-section .page-wrapper .entry-content .wp-block-table table th,
.page-section .page-wrapper .entry-content .wp-block-table table td {
  box-sizing: border-box;
  padding: 15px 20px;
  border: 1px solid #ccc;
  text-align: left;
}

.page-section .page-wrapper .entry-content .wp-block-table table th,
.page-section .page-wrapper .entry-content .wp-block-table table td:first-child {
  background: #F5F6F7;
}

.page-section .page-wrapper .entry-content .wp-block-button .wp-block-button__link {
  padding: 10px 24px;
  border-radius: 0;
  text-decoration: none;
  font-size: 87.5%;
}

.page-section .page-wrapper .entry-content .wp-block-button .wp-block-button__link:not([class*='has-']) {
  border: 1px solid #000;
  color: #000;
  background-color: transparent;
}

.page-section .page-wrapper .entry-content .wp-block-button.is-style-button-wide .wp-block-button__link {
  padding: 10px 5.120702268vw;
}

.page-section .page-wrapper .entry-content .wp-block-button.is-style-button-white .wp-block-button__link {
  border: 1px solid #fff;
  color: #fff;
}

.page-section .page-wrapper .entry-content .wp-block-cover {
  min-height: initial;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance {
  background: #F5F6F7;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance > .wp-block-cover__inner-container {
  padding: 5.856515373vw;
  max-width: 1140px;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance > .wp-block-cover__inner-container .wp-block-columns {
  margin-bottom: 0;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance > .wp-block-cover__inner-container .wp-block-columns .wp-block-column:nth-child(2n) {
  margin-left: 5.856515373vw;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance > .wp-block-cover__inner-container .wp-block-columns .wp-block-column .has-background-dim {
  padding: 5.856515373vw 9.150805271%;
  box-sizing: border-box;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance > .wp-block-cover__inner-container .wp-block-columns .wp-block-column .has-background-dim::before {
  opacity: 0.3;
}

.page-section .page-wrapper .entry-content .wp-block-cover.is-style-cover-guidance .wp-block-button .wp-block-button__link {
  border-color: #fff;
  color: #fff;
  font-size: 125%;
  font-weight: 700;
}

.page-section .page-wrapper .entry-content .wp-block-cover.contact-nav {
  padding: 120px 0;
  color: #fff;
  text-align: center;
}

.page-section .page-wrapper .entry-content .wp-block-cover.contact-nav::before {
  opacity: 0.3;
}

.page-section .page-wrapper .entry-content .wp-block-cover.contact-nav .wp-block-button .wp-block-button__link {
  border-color: #fff;
  color: #fff;
  font-size: 125%;
  font-weight: 700;
}

.page-section .page-wrapper .entry-content .wp-block-quote {
  padding: 60px 35px;
  border-left: 0;
  position: relative;
  background: #F5F6F7;
}

.page-section .page-wrapper .entry-content .wp-block-quote::before {
  content: '';
  position: absolute;
  left: 14px;
  top: 20px;
  display: block;
  width: 30px;
  height: 25px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-doubke-quotation%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2031.29%2026.66%22%3E%20%3Cpath%20d%3D%22M7.08%2C26.66h0A7.08%2C7.08%2C0%2C0%2C1%2C0%2C19.58C.33%2C5.34%2C8%2C.36%2C8.37.15L8.61%2C0h2.78a1.92%2C1.92%2C0%2C0%2C1%2C1.14.39%2C1.8%2C1.8%2C0%2C0%2C1%2C.28%2C2.54c-1.32%2C1.65-4.26%2C5.71-4.68%2C9.65A7.08%2C7.08%2C0%2C0%2C1%2C7.08%2C26.66ZM9.22%2C2C8%2C2.89%2C2.27%2C7.83%2C2%2C19.6a5.07%2C5.07%2C0%2C0%2C0%2C5.07%2C5.06h0a5.08%2C5.08%2C0%2C0%2C0%2C0-10.16h-1v-1c0-4.48%2C3-9%2C4.92-11.5ZM11.4%2C2Z%22%2F%3E%20%3Cpath%20d%3D%22M24.21%2C26.66h0a7.08%2C7.08%2C0%2C0%2C1-7.07-7.08C17.46%2C5.34%2C25.17.36%2C25.5.15L25.74%2C0h2.8a1.79%2C1.79%2C0%2C0%2C1%2C1.12.4%2C1.81%2C1.81%2C0%2C0%2C1%2C.28%2C2.53c-1.32%2C1.65-4.27%2C5.71-4.69%2C9.65a7.1%2C7.1%2C0%2C0%2C1%2C6%2C7%2C7.08%2C7.08%2C0%2C0%2C1-7.08%2C7.07ZM26.35%2C2c-1.19.89-7%2C5.83-7.22%2C17.6a5.07%2C5.07%2C0%2C0%2C0%2C5.07%2C5.06h0a5.08%2C5.08%2C0%2C1%2C0%2C0-10.16h-1v-1c0-4.47%2C3-9%2C4.93-11.5Zm2.18%2C0Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
}

.page-section .page-wrapper .entry-content .wp-block-file a::after {
  content: '';
  margin-left: 6px;
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-pdf%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2013.23%2013.63%22%3E%20%3Cpolygon%20points%3D%2211.88%2013.63%200%2013.63%200%203.02%202.83%200%2011.88%200%2011.88%203.56%2010.88%203.56%2010.88%201%203.27%201%201%203.42%201%2012.63%2010.88%2012.63%2010.88%209.83%2011.88%209.83%2011.88%2013.63%22%2F%3E%20%3Cpolygon%20points%3D%223.55%203.67%200.5%203.67%200.5%202.67%202.55%202.67%202.55%200.5%203.55%200.5%203.55%203.67%22%2F%3E%20%3Cpath%20d%3D%22M3.44%2C7.2V9H2.59V4.4H4.32a1.4%2C1.4%2C0%2C0%2C1%2C0%2C2.8Zm.85-.79a.62.62%2C0%2C0%2C0%2C0-1.23H3.44V6.41Z%22%2F%3E%20%3Cpath%20d%3D%22M6.15%2C9V4.4H7.59a2.31%2C2.31%2C0%2C0%2C1%2C0%2C4.6ZM7%2C8.21h.31a1.74%2C1.74%2C0%2C0%2C0%2C1-.3%2C1.72%2C1.72%2C0%2C0%2C0%2C0-2.43h0a1.67%2C1.67%2C0%2C0%2C0-1-.3H7Z%22%2F%3E%20%3Cpath%20d%3D%22M10.31%2C4.39h2.92v.79H11.17V6.24h1.66V7H11.17V9h-.86Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
  background-size: cover;
}

.page-section .page-wrapper .entry-content .wp-block-gallery ul {
  list-style: none;
}

.page-section .page-wrapper .entry-content .wp-block-gallery.is-style-fit ul li {
  margin: 0;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist {
  flex-wrap: wrap;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article {
  flex-basis: calc((100% - 96px)*0.25);
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article:nth-child(4n-3) {
  margin-left: 0;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article a {
  text-decoration: none;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-thumbnail {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-thumbnail::after {
  content: '';
  display: block;
  padding-top: 66.666666667%;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-thumbnail img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  max-width: initial;
  max-width: auto;
  transform: translate(-50%, -50%);
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-date {
  font-size: 75%;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-category {
  padding: 0 5px;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  background: #e2e2e2;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-title {
  font-size: 87.5%;
  font-weight: 700;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist article .post-content {
  font-size: 75%;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board {
  padding: 6px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 44px;
  overflow: hidden;
  position: relative;
}

@keyframes animation-marquee {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(-100%);
  }
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list {
  white-space: nowrap;
  position: absolute;
  animation-name: animation-marquee;
  animation-duration: 50s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list article {
  display: inline-block;
  white-space: nowrap;
  border-right: 1px solid #000;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list article a {
  padding: 11px;
  padding-right: 30vw;
  padding-left: 2em;
  position: relative;
  display: flex;
  align-items: center;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list article a > div {
  margin-left: 1em;
  font-size: 87.5%;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list article a > div.post-title {
  font-weight: 400;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list article a::after {
  content: '';
  position: absolute;
  right: 2em;
  display: block;
  width: 10px;
  height: 5px;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22icon-arrowtop%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2030.47%2015.94%22%3E%20%3Cpath%20d%3D%22M30.18%2C15.94a.51.51%2C0%2C0%2C1-.36-.15L15.45%2C1.41%2C1.06%2C15.79a.48.48%2C0%2C0%2C1-.7%2C0%2C.48.48%2C0%2C0%2C1%2C0-.7L15.45%2C0%2C30.53%2C15.09a.5.5%2C0%2C0%2C1-.35.85Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
  transform: rotate(90deg);
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .front-board-list article:first-child a {
  padding-left: 100vw;
}

.page-section .page-wrapper .entry-content .wp-block-articlelist.front-board .wp-block-button {
  position: absolute;
  top: 6px;
  right: 0;
  background: #fff;
}

.page-section .page-wrapper .entry-content .wp-block-archives.wp-block-archives-list,
.page-section .page-wrapper .entry-content .wp-block-categories.wp-block-categories-list,
.page-section .page-wrapper .entry-content .wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none;
}

.page-section .page-wrapper .entry-content .is-style-anchor-list .wp-block-column a {
  padding: 0.3em 1em;
  border-bottom: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 87.5%;
  font-weight: 700;
  text-decoration: none;
  box-sizing: border-box;
}

.page-section .page-wrapper .entry-content .is-style-anchor-list .wp-block-column a::after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-arrowbottom%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.26%207.04%22%3E%20%3Cpolygon%20points%3D%225.63%207.04%200%201.41%201.41%200%205.63%204.22%209.85%200%2011.26%201.41%205.63%207.04%22%2F%3E%20%3C%2Fsvg%3E") 0 0 no-repeat;
}

.page-section .page-wrapper .entry-content .definition-row dt {
  float: none;
  clear: both;
  width: auto;
}

.page-section .page-wrapper .entry-content .definition-row dd {
  padding: 0;
}

.page-section .page-wrapper .entry-content .definition-row.definition-faq dt {
  font-size: 125%;
}

.page-section .page-wrapper .entry-content .definition-row.definition-faq dt::before {
  content: 'Q.';
}

.page-section .page-wrapper .entry-content .definition-row.definition-faq dd::before {
  content: 'A.';
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container {
  display: flex;
  justify-content: space-between;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav {
  flex-basis: 40.952380952vw;
  width: 40.952380952vw;
  max-width: 473px;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav img {
  width: 100%;
  max-width: 100%;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element {
  position: relative;
  top: 0;
  z-index: 0;
  width: 40.952380952vw;
  transition: top 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element.is-fixed {
  position: fixed;
  top: 70px;
  left: 5.194805195vw;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-image img {
  margin: 0;
  height: auto;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-image > .wp-block-group__inner-container {
  position: relative;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-image > .wp-block-group__inner-container .nav-image-list li {
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-image > .wp-block-group__inner-container .nav-image-list li.current {
  opacity: 1;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-side {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-side li {
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-side li::after {
  content: '|';
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-side li:first-child::before {
  content: '|';
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-side li a {
  display: inline-block;
  padding: 3px;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-nav .fix-element .nav-side li a.current {
  color: #FF895D;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-content {
  overflow: hidden;
  flex-basis: 51.948051948%;
  width: 51.948051948%;
  flex-basis: 50%;
  width: 50%;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-content > .wp-block-group__inner-container > div {
  border-bottom: 1px solid #000;
  height: 600px;
  padding-top: 100px;
  padding: 50px 0;
}

.page-section .page-wrapper .entry-content .fix-range > .wp-block-group__inner-container .fix-content > .wp-block-group__inner-container > div:first-of-type {
  padding-top: 0;
}

.page-section .page-wrapper .entry-content .is-style-slider .swiper-wrapper,
.page-section .page-wrapper .entry-content .is-style-slider-simple .swiper-wrapper {
  flex-wrap: nowrap;
}

.page-section .page-wrapper .entry-content .is-style-slider .swiper-wrapper .blocks-gallery-item,
.page-section .page-wrapper .entry-content .is-style-slider-simple .swiper-wrapper .blocks-gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.page-section .page-wrapper .entry-content .is-style-slider .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::before, .page-section .page-wrapper .entry-content .is-style-slider .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::after,
.page-section .page-wrapper .entry-content .is-style-slider-simple .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::before,
.page-section .page-wrapper .entry-content .is-style-slider-simple .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::after {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
}

.page-section .page-wrapper .entry-content .is-style-slider .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::before,
.page-section .page-wrapper .entry-content .is-style-slider-simple .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::before {
  width: 34px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-eye%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2037.09%2020.21%22%3E%20%3Cpath%20d%3D%22M18.54%2C20.21c-9.86%2C0-17.66-9.07-18-9.46L0%2C10.1l.55-.64C.88%2C9.07%2C8.68%2C0%2C18.54%2C0s17.68%2C9.07%2C18%2C9.46l.55.64-.55.65C36.22%2C11.14%2C28.42%2C20.21%2C18.54%2C20.21ZM2.67%2C10.1c1.94%2C2%2C8.39%2C8.11%2C15.87%2C8.11s13.94-6.08%2C15.88-8.11C32.48%2C8.08%2C26%2C2%2C18.54%2C2S4.61%2C8.08%2C2.67%2C10.1Z%22%2F%3E%20%3Cpath%20d%3D%22M18.62%2C19.79a9.9%2C9.9%2C0%2C1%2C1%2C9.9-9.9A9.91%2C9.91%2C0%2C0%2C1%2C18.62%2C19.79ZM18.62%2C2a7.9%2C7.9%2C0%2C1%2C0%2C7.9%2C7.89A7.9%2C7.9%2C0%2C0%2C0%2C18.62%2C2Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
  background-position: 50% 50%;
  z-index: 2;
}

.page-section .page-wrapper .entry-content .is-style-slider .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::after,
.page-section .page-wrapper .entry-content .is-style-slider-simple .swiper-wrapper .blocks-gallery-item.swiper-slide-thumb-active::after {
  border: 2px solid #000;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.4;
  z-index: 1;
}

.page-section .page-wrapper .entry-content .alignfull {
  padding: 0;
  max-width: 100%;
}

.page-section .page-wrapper .entry-content .alignfull.wp-block-columns-gallery .wp-block-column {
  margin: 0;
}

.page-section .page-wrapper .entry-content .alignwide {
  max-width: 800px;
}

.page-section a {
  color: #000;
  text-decoration: underline;
}

.page-section a.blank::after {
  content: '';
  margin-left: 6px;
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-link%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2013.74%2013.78%22%3E%20%3Cpolygon%20points%3D%2212.96%2011.57%202.17%2011.57%202.17%200.79%207.57%200.79%207.57%201.78%203.17%201.78%203.17%2010.57%2011.96%2010.57%2011.96%206.18%2012.96%206.18%2012.96%2011.57%22%2F%3E%20%3Cpolygon%20points%3D%2213.74%205.39%2012.74%205.39%2012.74%201%208.35%201%208.35%200%2013.74%200%2013.74%205.39%22%2F%3E%20%3Cpolygon%20points%3D%2210.28%2013.78%200%2013.78%200%203.5%201%203.5%201%2012.78%2010.28%2012.78%2010.28%2013.78%22%2F%3E%20%3Crect%20x%3D%227.34%22%20y%3D%222.45%22%20width%3D%226.92%22%20height%3D%221%22%20transform%3D%22translate(1.08%208.5)%20rotate(-45)%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
  background-size: cover;
}

.page-section .visual-container {
  display: flex;
}

.page-section .visual-container .thumbnail-image {
  flex-basis: 49.963423555vw;
}

.page-section .visual-container .thumbnail-image img {
  width: 100%;
}

.page-section .visual-container .feature-container {
  flex-basis: calc(100% - (683/1367)*100vw);
}

.page-section .visual-container.front-visual {
  margin-right: 55px;
}

.page-section .visual-container.front-visual .front-title {
  flex-basis: 50vw;
}

.page-section .visual-container.front-visual .front-gallery {
  flex-basis: 50vw;
  width: 50vw;
  overflow: hidden;
}

.page-section .visual-container.front-visual .front-gallery .is-style-slider-simple .swiper-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.page-section .visual-container.front-visual .front-gallery .is-style-slider-simple .swiper-wrapper .swiper-slide {
  margin: 0;
}

.page-section .feature-container {
  margin-right: 55px;
  padding: 0 2.597402597vw;
  flex-basis: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.page-section .feature-container .feature-title {
  font-size: 187.5%;
  font-weight: 700;
}

.page-section .feature-container .feature-catch {
  font-size: 112.5%;
}

.page-section .wpcf7 {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
}

.page-section .wpcf7 br {
  display: none;
}

.page-section .wpcf7 .wpcf7-validation-errors,
.page-section .wpcf7 .wpcf7-acceptance-missing,
.page-section .wpcf7 .wpcf7-mail-sent-ok,
.page-section .wpcf7 .wpcf7-mail-sent-ng,
.page-section .wpcf7 .wpcf7-spam-blocked {
  border: 0;
  background: #F5F6F7;
  padding: 2em 1em;
}

.page-section .wpcf7 .wpcf7-response-output {
  margin: 0;
}

.page-section .wpcf7 .wpcf7-not-valid-tip {
  font-size: 75%;
}

.page-section .wpcf7 .form-group {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  display: flex;
}

.page-section .wpcf7 .form-group:last-of-type {
  border-bottom: 1px solid #000;
}

.page-section .wpcf7 .form-group label {
  padding: 1em;
  border-right: 1px solid #000;
  font-weight: 700;
  background: #F5F6F7;
  flex-basis: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-section .wpcf7 .form-group label span.asterisk {
  padding: 0 3px;
  font-size: 87.5%;
  font-weight: 400;
  background: #fff;
}

.page-section .wpcf7 .form-group label span.asterisk.required {
  color: #fff;
  background: #FF5522;
}

.page-section .wpcf7 .form-group .wpcf7-form-control-wrap {
  padding: 1em;
  flex-basis: 67%;
}

/* Element ----------------------------------------*/
dl.definition dt {
  font-weight: 900;
}

dl.definition-column dt {
  float: left;
  clear: left;
  width: 9em;
}

dl.definition-column dd {
  overflow: hidden;
}

dl.definition-row dt {
  display: block;
}

dl.definition-row dd {
  margin-bottom: 1.8em;
}

dl.definition-row dd:last-child {
  margin-bottom: 0;
}

dl.indentline dt {
  font-size: 125%;
  font-weight: 900;
  letter-spacing: 0.13em;
}

dl.indentline dt::before {
  content: '';
  margin-right: 1em;
  transform: translateY(-3px);
  display: inline-block;
  width: 31px;
  height: 5px;
  background: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20id%3D%22icon-indentline%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2031%205%22%3E%20%3Cpath%20d%3D%22M4%2C5%2C0%2C1.24.89.41%2C4%2C3.33%2C7.58%2C0l3.49%2C3.26%2C3-2.85.89.84L11.07%2C4.92%2C7.58%2C1.66Z%22%2F%3E%20%3Cpath%20d%3D%22M20%2C5%2C16%2C1.24l.89-.83L20%2C3.33%2C23.58%2C0l3.49%2C3.26%2C3-2.85.89.84L27.07%2C4.92%2C23.58%2C1.66Z%22%2F%3E%20%3C%2Fsvg%3E") no-repeat;
}

dl.indentline dd {
  margin-bottom: 1.8em;
  font-size: 106.25%;
  letter-spacing: 0.13em;
  line-height: 1.882352941;
}

dl.indentline dd:last-of-type {
  margin-bottom: 0;
}

.breadcrumb ol {
  margin: 0 auto;
  padding-top: 0.950987564vw;
  padding-bottom: 0.950987564vw;
  padding-left: 1.243599122vw;
  padding-right: 55px;
  max-width: 1311px;
  list-style: none;
  box-sizing: border-box;
  display: flex;
  align-items: top;
}

.breadcrumb ol li::after {
  content: '>';
  margin: 0 0.5em;
}

.breadcrumb ol li:last-of-type::after {
  content: none;
  margin: 0;
}

.pagination {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.pagination .num {
  text-align: center;
  display: flex;
  justify-content: center;
}

.pagination span,
.pagination a {
  margin: 0 5px;
  border: 1px solid #000;
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  color: #000;
  box-sizing: border-box;
}

.pagination span.current {
  border: 1px solid #e2e2e2;
  color: #fff;
  background: #e2e2e2;
  cursor: default;
}

.pagination .prev,
.pagination .next {
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}

.pagination .prev.disabled,
.pagination .next.disabled {
  border: 1px solid transparent;
  color: transparent;
  cursor: default;
}

.pagination .prev {
  margin-left: 0;
}

.pagination .next {
  margin-right: 0;
}

.hide {
  display: none;
}

.aligncenter {
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 1270px) {
  /* Mediaquery */
  br.sp {
    display: none;
  }
}

@media (max-width: 1270px) {
  /* Mediaquery */
  br.sp {
    display: none;
  }
}

@media (max-width: 1024px) {
  /* Mediaquery */
  html {
    -webkit-text-size-adjust: 100%;
  }
  br.sp {
    display: none;
  }
}

@media (max-width: 959px) {
  /* Mediaquery */
  br.sp {
    display: none;
  }
}

@media (max-width: 740px) {
  /* Mediaquery */
  html {
    -webkit-text-size-adjust: 100%;
  }
  header {
    padding: 0;
    display: block;
  }
  header .logo-brand {
    width: 100%;
    position: absolute;
    top: 14px;
    z-index: 1000;
  }
  header .logo-brand h1 {
    display: block;
  }
  header .logo-brand h1 a {
    margin: 0 auto;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .logo-brand h1 a .svg {
    display: block;
  }
  header .logo-brand h1 a .svg-logo {
    margin-right: 4px;
    width: 39px;
    height: 32px;
  }
  header .logo-brand h1 a .svg-name {
    margin-left: 4px;
    width: 70px;
    height: 18px;
  }
  header .header-nav ul {
    display: block;
  }
  header.is-fixed {
    padding: 0;
  }
  .open header .logo-brand h1 a .svg-name {
    fill: #000;
  }
  #btn-nav {
    z-index: 1000;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  #btn-nav::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 33%;
    height: 2px;
    display: block;
    background: #000;
    transform: translateX(-50%);
    transition: transform 0.3s ease-in-out, top 0.3s ease;
  }
  #btn-nav span::before, #btn-nav span::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 33%;
    height: 2px;
    display: block;
    background: #000;
    transform: translateX(-50%);
    transition: transform 0.3s ease-in-out, top 0.3s ease;
  }
  #btn-nav span::before {
    top: calc(50% - 5px);
  }
  #btn-nav span::after {
    top: calc(50% + 5px);
  }
  .open #btn-nav::before {
    top: 50%;
    left: 50%;
    width: 0;
  }
  .open #btn-nav span::before {
    top: 60%;
    left: calc(50% - 5px);
    transform: rotate(45deg) translateX(-50%);
  }
  .open #btn-nav span::after {
    top: 37%;
    left: calc(50% - 5px);
    transform: rotate(-45deg) translateX(-50%);
  }
  #global-nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 990;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    transform: translateX(-100%);
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  #global-nav nav {
    z-index: 999;
  }
  #global-nav a {
    padding: 10px 0;
    display: block;
    font-size: 150%;
  }
  #global-nav ul li {
    transform: translateX(-200px);
    transition: transform 0.6s ease;
    position: relative;
    text-align: center;
  }
  #global-nav ul li:nth-child(2) {
    transition-delay: 0.15s;
  }
  #global-nav ul li:nth-child(3) {
    transition-delay: 0.3s;
  }
  #global-nav ul li:nth-child(4) {
    transition-delay: 0.45s;
  }
  #global-nav ul li:nth-child(5) {
    transition-delay: 0.6s;
  }
  #global-nav ul li:nth-child(6) {
    transition-delay: 0.75s;
  }
  #global-nav ul li:nth-child(7) {
    transition-delay: 0.9s;
  }
  .open #global-nav {
    transform: translateX(0);
    background: #fff;
  }
  .open #global-nav li {
    transform: translateX(0);
  }
  .open #global-nav li a {
    color: #000;
  }
  footer .page-wrapper .footer-nav {
    justify-content: space-between;
  }
  .feature-title {
    font-size: 10.666666667vw;
    letter-spacing: 0.13em;
  }
  .feature-catch {
    font-size: 6.4vw;
    letter-spacing: 0.23em;
    line-height: 1.666666667;
  }
  #scroll-top {
    display: none;
  }
  br.sp {
    display: block;
  }
}

/* Modernizr touchevents */
